import React from 'react';
import { SiteMain } from "../../styles/shared";
import { inner, outer } from "../header/SiteNav";

interface IContentProps {

}

export class Content extends React.Component<IContentProps> {
    static defaultProps = {
    };

    render() {
        return (
            <main id="site-main" className="site-main" css={[SiteMain, outer]}>
                <div css={inner}>
                    {this.props.children}
                </div>
            </main>
        );
    }
}